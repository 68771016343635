import { ConfigProvider } from "../contexts/ConfigContext";
import { CssBaseline } from "@material-ui/core";
import Main from "./Main";
import { BrowserRouter as Router } from "react-router-dom";

function App() {
  return (
    <Router>
      <ConfigProvider>
        <CssBaseline />
        <Main />
      </ConfigProvider>
    </Router>
  );
}

export default App;
