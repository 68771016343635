import { createContext, useEffect, useState } from "react";

import { sources } from "../types/source";

export const ConfigContext = createContext();

export const ConfigProvider = ({ children }) => {
  const [loaded, setLoaded] = useState(false);
  const [source, setSource] = useState(sources.jvcfzq);

  useEffect(() => {
    setLoaded(true);
  }, []);

  return (
    <ConfigContext.Provider value={{ loaded, source, setSource }}>
      {children}
    </ConfigContext.Provider>
  );
};
