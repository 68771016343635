import pools from "../pools";

export function getReferrerLink() {
  return window.location.pathname.split("/")[1];
}

export function getEnvironment(hostname) {
  return hostname === "localhost" ? "dev" : hostname.split(".")[0];
}

export function isDevelopment() {
  const environment = getEnvironment(window.location.hostname);
  return environment === "dev";
}

export function getPool(pools, hostname) {
  return pools.find(p => p.name === getEnvironment(hostname));
}

export function getTitle() {
  const pool = getPool(pools, window.location.hostname);
  return pool ? pool.title : "TalentDriver";
}

export function getName() {
  const pool = getPool(pools, window.location.hostname);
  return pool ? pool.name : "test";
}
