import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import { Suspense, lazy } from "react";
import { Route, Switch } from "react-router-dom";

import { FormProvider } from "../contexts/FormContext";
import { SnackbarProvider } from "notistack";
import { withRouter } from "react-router";

const Home = lazy(() => import("./Home"));

let theme = createMuiTheme({});

theme = {
  ...theme,
  overrides: {}
};

function Main(props) {
  return (
    <MuiThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <FormProvider>
          <Suspense fallback={<></>}>
            <Switch>
              <Route path="*" exact component={Home} />
            </Switch>
          </Suspense>
        </FormProvider>
      </SnackbarProvider>
    </MuiThemeProvider>
  );
}

export default withRouter(Main);
