export const sources = {
  jvcfzq: 1, // TalentDriver
  gzivhe: 6, // Facebook
  fsaqva: 7 // Instagram
};

export const sourcesEnum = {
  talentdriver: 1, // TalentDriver
  facebook: 6, // Facebook
  instagram: 7 // Instagram
};
