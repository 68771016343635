import { createContext, useState } from "react";

export const FormContext = createContext();

export const FormProvider = ({ children }) => {
  const [values, setValues] = useState();
  const [submitted, setSubmitted] = useState(false);

  return (
    <FormContext.Provider
      value={{ values, setValues, submitted, setSubmitted }}
    >
      {children}
    </FormContext.Provider>
  );
};
